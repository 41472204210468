/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.marketplace-swiper .swiper-button-next, .marketplace-swiper .swiper-button-prev {
    top: 24px;
}

.marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 22px;
    line-height: 2;
}

.marquee div {
    display: block;
    width: 200%;
    height: 30px;
    position: absolute;
    overflow: hidden;
    animation: marquee 35s linear infinite;
}

.marquee span {
    float: left;
    width: 50%;
}

@keyframes marquee {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}